import styled, { css } from 'styled-components'
import { CardWrapper } from '../CardSection/styles'
import { Wrapper } from '../../components/PageBuilderSection/styles'
import { TGridColumns } from './types'

interface Props {
  $gridColums: string
  $gridCMSColumns: TGridColumns
}

export const GridWrapper = styled.div<Props>`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1920px;

  display: grid;
  grid-template-columns: ${({ $gridColums }) => $gridColums || '1fr'};
  grid-gap: 10px;
  justify-items: stretch;
  align-items: stretch;

  ${({ $gridCMSColumns }) =>
    $gridCMSColumns === 'Single 1/1' &&
    css`
      aspect-ratio: 16 / 7;
    `}

  ${Wrapper} {
    height: 100%;
  }

  ${CardWrapper} {
    aspect-ratio: unset;

    ${({ theme }) => '@media ' + theme.mediaQueries.tablet} {
      aspect-ratio: 16 / 7;
    }
  }

  ${({ $gridColums }) =>
    $gridColums.split(' ').length > 2 &&
    css`
      ${CardWrapper} {
        aspect-ratio: unset;

        ${({ theme }) => '@media ' + theme.mediaQueries.tablet} {
          aspect-ratio: 16 / 7;
        }
      }
    `}

  ${({ theme }) => '@media ' + theme.mediaQueries.maxLg} {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`
